<template>
  <div>
    <b-table :class="tableClasses" :items="items" :fields="fields">
      <template v-slot:head()="{ field, label }">
        <template v-if="isExistSlot(`head-${field.key}`)">
          <slot :name="`head-${field.key}`" :scopeProps="label" />
        </template>
      </template>

      <template v-slot:cell()="{ item, field, value }">
        <template v-if="isExistSlot(`cell-${field.key}`)">
          <div v-if="field.key === 'actions'" class="actions">
            <slot :name="`cell-actions`" :scopeProps="{ item, value }" />
          </div>

          <slot v-else :name="`cell-${field.key}`" :scopeProps="{ item, value }" />
        </template>

        <template v-else-if="field.type === 'date'">
          {{ $dateConverter(value) }}
        </template>

        <template v-else-if="field.type === 'email'">
          <b-link class="crop-text" :href="`mailto:${value}`">
            {{ value }}
          </b-link>
        </template>

        <template v-else-if="field.type === 'link'">
          <b-link v-if="value.route" :data-cy="`${field.key}-link`" :to="value.route">
            {{ value.title }}
          </b-link>

          <span v-else>
            {{ value }}
          </span>
        </template>

        <template v-else-if="field.type === 'status'">
          <StatusItem :data-cy="`${value.code}-status`" :color="value.color" :label="value.label" />
        </template>

        <template v-else-if="field.type === 'amount'">
          <span v-if="value.type === 'accrual'" data-cy="income-amount">
            {{ `+ ${value.amount} ${currency}` }}
          </span>

          <span v-else data-cy="outcome-amount" class="text-danger">
            {{ `– ${value.amount} ${currency}` }}
          </span>
        </template>

        <template v-else>
          <span :data-cy="`${field.key}-cell`">
            {{ value }}
          </span>
        </template>
      </template>
    </b-table>

    <OurPagination
      v-if="isMoreThanOnePage"
      :current-page="paginationData.currentPage"
      :per-page="paginationData.perPage"
      :total="paginationData.total"
      @pageChange="onPageChange"
    />

    <EmptyTableMsg :items="items" :filters="filters" />
  </div>
</template>

<script>
import dateMixin from "@/mixins/date.mixin";
import { mapState } from "vuex";

import StatusItem from "@/components/StatusItem";
import OurPagination from "@/components/table/OurPagination";
import EmptyTableMsg from "@/components/table/EmptyTableMsg";

export default {
  name: "OurTable",
  components: {
    StatusItem,
    OurPagination,
    EmptyTableMsg,
  },

  mixins: [dateMixin],

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    fields: {
      type: Array,
      default: () => [],
    },

    paginationData: {
      type: Object,
      default: () => ({}),
    },

    tableClasses: {
      type: String,
      default: "",
    },

    filters: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapState("currency", ["currency"]),

    isMoreThanOnePage() {
      return this.paginationData.lastPage > 1;
    },
  },

  methods: {
    isExistSlot(slotName) {
      return !!this.$scopedSlots[slotName];
    },

    onPageChange(page) {
      this.$emit("pageChange", page);
    },
  },
};
</script>

<style lang="scss" scoped>
table::v-deep {
  tr {
    &.b-table-top-row {
      input {
        border: 1px solid transparent;
        border-radius: 4px;
      }
      &:hover {
        background-color: transparent;
        cursor: default;

        input {
          border: 1px solid #ecf0f3;
        }
      }
      & + tr td {
        border-top-color: transparent;
      }
      td {
        border-bottom: 1px solid #ecf0f3;
      }
    }
    .filter-input {
      max-width: inherit !important;
      margin-left: -10px;
      padding: 5px 10px;
    }
  }
  &:first-child {
    td {
      border-top-color: transparent !important;
    }
  }
  tbody {
    tr {
      cursor: default;

      &:hover {
        background-color: #f6f7fb;

        .actions {
          .svg-icon {
            background-color: #f6f7fb;
            opacity: 1;
            filter: grayscale(0);
          }
        }
        &.sortable-ghost {
          .svg-icon {
            background-color: #eaeffe !important;
          }
        }
      }

      .actions {
        text-align: right;
        white-space: nowrap;

        .svg-icon {
          filter: grayscale(100%);
          opacity: 0.25;
          cursor: pointer;
        }
      }
      .dragzone {
        width: 42px;
        cursor: pointer;

        .svg-icon {
          position: relative;
          bottom: 1px;
        }
      }
    }
  }
  .custom-header {
    display: none;
  }
}

.our-table::v-deep {
  thead {
    th {
      padding: 0.75rem;
      white-space: nowrap;

      div {
        border-right: 1px solid #ebedf3;
        padding: 0.4rem 1.3rem 0.4rem 0;
      }

      &:first-child {
        padding-left: 1.7rem;
      }

      &:last-child {
        div {
          border-right: none;
          padding-right: 0;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 1rem 1.7rem 1rem 0.75rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
          padding-left: 1.7rem;
        }

        &:last-child {
          padding-left: 0;
          padding-right: 1.5rem;
        }
      }
    }
  }

  .crop-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
