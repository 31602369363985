<template>
  <b-modal
    :id="modalId"
    v-model="isShownModal"
    :title="title"
    :centered="isCentered"
    :size="modalSize"
    :hide-header="hideHeader"
    :hide-footer="hideFooter"
    :no-close-on-backdrop="noClose"
    :no-close-on-esc="noClose"
    :body-class="bodyClass"
    :content-class="contentClass"
  >
    <slot />

    <template v-slot:modal-footer="props">
      <slot name="footer" :scopeProps="props" />
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "PageModal",
  props: {
    modalId: {
      type: String,
      default: "confirm-modal",
    },

    title: {
      type: String,
      default: "",
    },

    hideHeader: {
      type: Boolean,
      default: false,
    },

    hideFooter: {
      type: Boolean,
      default: false,
    },

    modalSize: {
      type: String,
      default: "md",
    },

    isCentered: {
      type: Boolean,
      default: false,
    },

    noClose: {
      type: Boolean,
      default: false,
    },

    bodyClass: {
      type: String,
      default: "",
    },

    contentClass: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    isShownModal: false,
  }),

  watch: {
    isShownModal: "onChangeShownModal",
  },

  methods: {
    showModal() {
      this.isShownModal = true;
    },

    closeModal() {
      this.isShownModal = false;
    },

    onChangeShownModal() {
      this.$emit("changeShownModal", this.isShownModal);
    },
  },
};
</script>
