<template>
  <MultiselectElement
    :value="selectedAgent"
    :options="agentList"
    :label="this.$t('label.agent._')"
    :type="type"
    :with-error="withError"
    :error-message="errorMessage"
    :disabled="disabled"
    :required="required"
    @select="onSelect"
    @searchChange="onSearchChange"
    @open="onOpen"
  />
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { agentsData, agentData } from "./gql/queries";

import MultiselectElement from "@/components/form/MultiselectElement";

export default {
  name: "MultiselectAgent",

  components: {
    MultiselectElement,
  },

  mixins: [apolloMixin],

  props: {
    value: {
      type: [String, Array],
      default: "",
    },

    type: {
      type: String,
      default: "",
    },

    required: {
      type: Boolean,
      default: false,
    },

    withError: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: function () {
        this.$t("validation.required");
      },
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      agents: [],
      agent: {},
      query: "",
    };
  },

  computed: {
    selectedAgent: {
      get() {
        return this.isTypeString ? [this.agent] : this.value;
      },
      set(value) {
        this.$emit("select", value);
      },
    },

    isTypeString() {
      return typeof this.value === "string";
    },

    agentList() {
      return this.agents
        .filter((agent) => agent.user)
        .map((agent) => {
          return {
            id: agent.user.id,
            label: `${agent.user.firstName} ${agent.user.lastName}`,
            agentHash: agent.hash,
          };
        });
    },

    isAgentInList() {
      return !!this.agentList.find((agent) => agent.id === this.value);
    },
  },

  watch: {
    value: "setAgent",
    agentList: "setAgent",
  },

  created() {
    this.getAgents();
  },

  methods: {
    async getAgents() {
      const filters = {};

      if (this.query) {
        filters.where = {
          relation: "user",
          column: "fullName",
          operator: "like",
          value: this.query,
        };
      }

      const { agents } = await this.$get(agentsData, { filters });

      this.agents = agents.data;
    },

    async getAgent() {
      if (this.value) {
        const { agent } = await this.$get(agentData, { userId: this.value });

        this.agent = {
          id: agent.user.id,
          label: `${agent.user.firstName} ${agent.user.lastName}`,
          agentHash: agent.hash,
        };
      }
    },

    setAgent() {
      if (this.isTypeString && this.agentList.length) {
        if (this.isAgentInList) {
          this.agent = this.agentList.find((agent) => agent.id === this.value);
        } else {
          this.getAgent();
        }
      }
    },

    onSelect(value) {
      this.selectedAgent = value;
    },

    onSearchChange(query) {
      this.query = query;
      this.getAgents();
    },

    onOpen() {
      this.$emit("open");
    },
  },
};
</script>
