<template>
  <b-button
    ref="btn"
    :pill="pill"
    class="btn font-weight-bold px-6"
    :class="[activeClass, `btn-${size}`]"
    :variant="variant"
    @click="onClick"
  >
    <slot>
      {{ $t(`button.${text}`) }}
    </slot>
  </b-button>
</template>

<script>
export default {
  name: "ButtonElement",
  props: {
    variant: {
      type: String,
      default: "secondary",
    },

    text: {
      type: String,
      // required: true,
    },

    pill: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: "md",
    },

    noFocus: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    activeClass: "",
  }),

  mounted() {
    const [variantType, variantStyle] = this.variant.split("-");

    this.activeClass =
      variantType === "text" ? `btn-hover-light-${variantStyle}` : "btn-shadow-hover";
  },

  methods: {
    onClick() {
      if (this.noFocus) this.$refs.btn.blur();

      this.$emit("click");
    },
  },
};
</script>
