<template>
  <div v-if="!isLoading && !items.length" class="empty-table-msg">
    {{ emptyTableMsg }}
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EmptyTableMsg",
  props: {
    items: {
      type: Array,
      default: () => [],
    },

    filters: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapState("loader", ["isLoading"]),

    emptyTableMsg() {
      const isFiltering = Object.values(this.filters).some((value) => value.length);

      return isFiltering
        ? this.$t("component.emptyTableMsg.noResultsForFilters")
        : this.$t("component.emptyTableMsg.noItems");
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-table-msg {
  display: flex;
  justify-content: center;
  margin: 30px;
  font-size: 16px;
  color: #80808f;
  font-weight: 200;
}
</style>
