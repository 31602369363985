import gql from "graphql-tag";

export const agentsData = gql`
  query agents($filters: Filter) {
    agents(filters: $filters) {
      data {
        user {
          id
          firstName
          lastName
        }
        hash
      }
    }
  }
`;

export const agentData = gql`
  query agentData($userId: ID!) {
    agent(userId: $userId) {
      user {
        id
        firstName
        lastName
      }
      hash
    }
  }
`;
