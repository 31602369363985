<template>
  <div class="datepicker-range">
    <DatePickerElement
      v-model="date.from"
      class="datepicker-element-from"
      :label="label?.dateFrom"
      :placeholder="placeholder?.dateFrom"
      :max-value="date.to"
      hide-header
    />

    <DatePickerElement
      v-model="date.to"
      class="datepicker-element-to"
      :label="label?.dateTo"
      :placeholder="placeholder?.dateTo"
      :min-value="date.from"
      hide-header
    />
  </div>
</template>

<script>
import DatePickerElement from "@/components/form/DatePickerElement";

export default {
  name: "DatePickerRange",

  components: {
    DatePickerElement,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    label: {
      type: Object,
      default: () => {},
    },

    placeholder: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      date: {
        from: "",
        to: "",
      },
    };
  },

  watch: {
    value: {
      handler: "onChangeValue",
      immediate: true,
      deep: true,
    },

    date: {
      handler: "onChangeDate",
      deep: true,
    },
  },

  methods: {
    onChangeValue() {
      if (this.value) {
        this.date = this.value;
      }
    },

    onChangeDate() {
      this.$emit("input", this.date);
    },
  },
};
</script>

<style lang="scss" scoped>
.datepicker-range {
  width: 100%;
  display: flex;
  align-items: center;

  .datepicker-element-from,
  .datepicker-element-to {
    width: 50%;
  }

  .datepicker-element-from + .datepicker-element-to {
    margin-left: 10px;
  }
}
</style>
