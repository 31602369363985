<template>
  <div role="group" class="form-group">
    <div v-if="label" class="form-group__label-wrapper">
      <label
        class="form-group__label"
        :class="{ required, 'label-color': isOpen, 'error-label': withError && !value }"
      >
        {{ label }}
      </label>
    </div>

    <b-form-datepicker
      ref="datepicker"
      class="datepicker-element"
      :class="{ 'border-active': isOpen, error: withError && !value }"
      :value="date"
      v-bind="localeLabels[activeLang]"
      value-as-date
      :start-weekday="startWeekday"
      :locale="activeLang"
      :today-button="todayButton"
      :reset-button="clearButton"
      :label-reset-button="$t('btn.reset')"
      :close-button="closeButton"
      :label-close-button="$t('btn.close')"
      label-help=""
      :date-format-options="dateFormat"
      :hide-header="hideHeader"
      :placeholder="placeholder"
      :min="minValue"
      :max="maxValue"
      @shown="changeLabelColor(true)"
      @hidden="changeLabelColor(false)"
      @input="dateSelected"
    />
  </div>
</template>

<script>
import i18nService from "@/services/i18n.service";

export default {
  name: "DatePickerElement",

  props: {
    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    value: {
      type: [String, Date],
      default: "",
    },

    minValue: {
      type: [String, Date],
      default: "",
    },

    maxValue: {
      type: [String, Date],
      default: "",
    },

    required: {
      type: Boolean,
      default: false,
    },

    todayButton: {
      type: Boolean,
      default: false,
    },

    clearButton: {
      type: Boolean,
      default: false,
    },

    closeButton: {
      type: Boolean,
      default: false,
    },

    hideHeader: {
      type: Boolean,
      default: false,
    },

    dateFormat: {
      type: Object,
      default: () => ({ year: "numeric", month: "2-digit", day: "2-digit" }),
    },

    startWeekday: {
      type: Number,
      default: 1,
    },

    withError: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isOpen: false,
    componentCodeForUkraine: "uk",
    codeOfUkraine: "ua",
    activeLang: "",
  }),

  computed: {
    date: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    labels() {
      return {
        labelPrevYear: this.$t("component.datepicker.labelPrevYear"),
        labelPrevMonth: this.$t("component.datepicker.labelPrevMonth"),
        labelCurrentMonth: this.$t("component.datepicker.labelCurrentMonth"),
        labelNextMonth: this.$t("component.datepicker.labelNextMonth"),
        labelNextYear: this.$t("component.datepicker.labelNextYear"),
      };
    },

    localeLabels() {
      return {
        uk: { ...this.labels },
        ru: { ...this.labels },
      };
    },
  },

  created() {
    const activeLang = i18nService.getActiveLanguage();

    this.activeLang = activeLang === this.codeOfUkraine ? this.componentCodeForUkraine : activeLang;
  },

  methods: {
    changeLabelColor(bool) {
      this.isOpen = bool;
    },

    dateSelected(date) {
      this.date = date;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;

  .form-group__label {
    color: $gray-500;
    margin-left: 5px;
    padding: 0 5px;
    position: absolute;
    left: 5px;
    top: -14px;
    background-color: white;
    border-radius: 4px;
    z-index: 1;
  }

  .form-group__label.required {
    &:after {
      content: "*";
      position: absolute;
      color: $danger;
      font-weight: bold;
      right: -5px;
    }
  }

  .label-color {
    color: $primary !important;
  }

  .form-control::v-deep {
    label {
      font-weight: 700;
    }
  }

  .focus {
    border-color: #e4e6ef;
  }

  .border-active {
    border: 1px solid $primary;
  }

  .datepicker-element::v-deep {
    height: 41px !important;
    width: 100%;
    flex-grow: 1;

    .btn.btn-outline-secondary {
      &:hover,
      &:focus {
        background: none;
      }
    }

    .dropdown-menu {
      border-radius: 0.25rem;

      .form-control {
        border-radius: 0.25rem;

        &:focus {
          border-color: #e4e6ef;
        }
      }
    }
  }
}

.error {
  border-color: $danger;
}

.error-label {
  color: $danger !important;
}
</style>
