<template>
  <div class="d-flex justify-content-center mt-4">
    <b-pagination v-model="currentPageModel" :per-page="perPage" :total-rows="total" />
  </div>
</template>

<script>
export default {
  name: "OurPagination",

  props: {
    currentPage: {
      type: Number,
      default: 1,
    },

    perPage: {
      type: Number,
      default: 1,
    },

    total: {
      type: Number,
      default: 1,
    },
  },

  computed: {
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(page) {
        this.$emit("pageChange", page);
      },
    },
  },
};
</script>
