<template>
  <div data-cy="filters">
    <template v-for="(option, index) in filterOptions">
      <MultiselectElement
        v-if="isSelect(option.component)"
        :key="index"
        :data-cy="`${option.key}-filter`"
        :class="option.classes"
        :type="option.type"
        :value="transformSelectValue(filters[option.key], option)"
        :options="option.selectOptions"
        :label="option.label"
        :item-label="option.itemLabel"
        :track-by="option.trackBy"
        @select="onSelect($event, option)"
      />

      <MultiselectAgent
        v-if="isSelectAgent(option.component)"
        :key="index"
        :data-cy="`${option.key}-filter`"
        :type="option.type"
        :value="filters[option.key]"
        @select="onSelect($event, option)"
      />

      <InputElement
        v-if="isInput(option.component)"
        :key="index"
        v-model="filters[option.key]"
        :data-cy="`${option.key}-filter`"
        :class="option.classes"
        :input-type="option.type"
        :label="option.label"
      />

      <DatePickerRange
        v-if="isDatePickerRange(option.component)"
        :key="index"
        v-model="filters[option.key]"
        class="datepicker-range"
        :label="option.label"
        :placeholder="option.placeholder"
      />
    </template>
  </div>
</template>

<script>
import InputElement from "@/components/form/InputElement";
import MultiselectElement from "@/components/form/MultiselectElement";
import MultiselectAgent from "@/components/form/MultiselectAgent";
import DatePickerRange from "@/components/form/DatePickerRange";

export default {
  name: "OurFilters",
  components: {
    InputElement,
    MultiselectElement,
    MultiselectAgent,
    DatePickerRange,
  },

  props: {
    fields: {
      type: Array,
      default: () => [],
      required: true,
    },

    appliedFilters: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    filters: {},
  }),

  computed: {
    filterOptions() {
      return this.fields
        .filter((field) => field.filter)
        .map((field) => {
          const key = field.key;
          const label = field.label;
          const type = field.filter.type;
          const component = field.filter.component;
          const classes = field.filter?.classes || "";

          let filtersOptions = {
            key,
            label,
            type,
            component,
            classes,
          };

          if (component === "select") {
            filtersOptions = {
              ...filtersOptions,
              selectOptions: field.filter.selectOptions,
              trackBy: field.filter?.trackBy || "id",
              itemLabel: field.filter?.itemLabel || "label",
            };
          }

          return filtersOptions;
        });
    },
  },

  created() {
    this.filters = this.appliedFilters;
  },

  methods: {
    isSelect(component) {
      return component === "select";
    },

    isSelectAgent(component) {
      return component === "select-agent";
    },

    isInput(component) {
      return component === "input";
    },

    isDatePickerRange(component) {
      return component === "datepicker-range";
    },

    transformSelectValue(value, { selectOptions = [], trackBy }) {
      return selectOptions.filter((item) => item[trackBy] === value);
    },

    onSelect(selectedValue, { key, trackBy = "id" }) {
      this.$set(this.filters, key, selectedValue[trackBy]);
    },

    applyFilters() {
      this.$emit("applyFilters", this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  &:last-child {
    margin-bottom: 0.5rem;
  }
}

.datepicker-range:deep {
  .datepicker-element-from {
    .dropdown-menu.show {
      left: -1px !important;
      top: -17px !important;
    }
  }

  .datepicker-element-to {
    .dropdown-menu.show {
      left: -64px !important;
      top: -17px !important;
    }
  }
}
</style>
